<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label>Kode Termin</label>
              <InputText
                v-model="form.code"
                :class="{ 'p-invalid': v$.form.code.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="3"
              />
              <small
                v-if="
                  (v$.form.code.$invalid && submitted) ||
                  v$.form.code.$pending.$response
                "
                class="p-error"
                >Kode termin tidak boleh kosong</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Termin</label>
              <div
                class="p-inputgroup col-12 md:col-12"
                style="margin-left: -10px"
              >
                <InputNumber
                  v-model="form.term"
                  :class="{
                    'p-invalid': v$.form.term.$invalid && submitted,
                  }"
                  :minFractionDigits="0"
                  :maxFractionDigits="0"
                  locale="id-ID"
                  :min="0"
                  class="w-full"
                />
                <span class="p-inputgroup-addon">hari</span>
              </div>
              <small
                v-if="
                  (v$.form.term.$invalid && submitted) ||
                  v$.form.term.$pending.$response
                "
                class="p-error"
                >{{ v$.form.term.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Deskripsi</label>
              <InputText
                v-model="form.desc"
                :class="{ 'p-invalid': v$.form.desc.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
              <small
                v-if="
                  (v$.form.desc.$invalid && submitted) ||
                  v$.form.desc.$pending.$response
                "
                class="p-error"
                >{{ v$.form.desc.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        code: {
          required: helpers.withMessage('Kode Termin harus diisi.', required),
        },
        term: {
          required: helpers.withMessage('Termin harus diisi.', required),
        },
        desc: {
          required: helpers.withMessage('Deskripsi harus diisi.', required),
        },
      },
    }
  },
}
</script>
